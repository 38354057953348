import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./styles/index.css";
import "./VueTailwind.js";
import PortalVue from "portal-vue";
import "iframe-resizer/js/iframeResizer.contentWindow.min.js";
import iframeUtils from "@/mixins/iframeUtils.js";

// global components
import SvgIcon from "@/components/BaseComponents/SvgIcon.vue";

import router from "./router";
Vue.component("SvgIcon", SvgIcon);
Vue.use(PortalVue);

Vue.config.productionTip = false;
Vue.mixin(iframeUtils);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

// event bus
export const bus = new Vue();
