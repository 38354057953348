import { cloneDeep } from "lodash-es";

const defaultState = {
  zoomAmount: 100,
  isModalOpen: false,
};

const mutations = {
  zoomAmountUpdate(state, value) {
    state.zoomAmount = value;
  },
  isModalOpenUpdate(state, value) {
    state.isModalOpen = value;
  },
};
const actions = {
  zoomAmountUpdate(context, value) {
    context.commit("zoomAmountUpdate", value);
  },
  isModalOpenUpdate(context, value) {
    context.commit("isModalOpenUpdate", value);
  },
};
const getters = {
  zoomAmountGet: (state) => state.zoomAmount,
  isModalOpenGet: (state) => state.isModalOpen,
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  actions,
  mutations,
};
