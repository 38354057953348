export const availableAddibles = [
  { id: 1, name: "Signature", iconName: "signature", type: "signature", hasAction: true },
  { id: 2, name: "Initial", iconName: "initial", type: "initial", hasAction: true },
  { id: 3, name: "Stamp", iconName: "stamp", type: "stamp", hasAction: true },
  { id: 4, name: "Date Signed", iconName: "calendar", type: "sign_date", hasAction: false },
  { id: 5, name: "Name", iconName: "user", type: "name", hasAction: false },
  { id: 6, name: "Email", iconName: "email", type: "email", hasAction: false },
];

export const availableAddiblesByType = {
  signature: availableAddibles[0],
  initial: availableAddibles[1],
  stamp: availableAddibles[2],
  sign_date: availableAddibles[3],
  name: availableAddibles[4],
  email: availableAddibles[5],
};
