import { cloneDeep } from "lodash-es";

const defaultState = {
  docRenderedPageNumbers: [],
  // activeAddible is the selected addible type to add to overlay
  activeAddible: null,
  activeDocId: null,
};

const mutations = {
  docRenderedPageNumbersUpdate(state, payload) {
    state.docRenderedPageNumbers = payload;
  },
  activeAddibleUpdate(state, payload) {
    state.activeAddible = payload;
  },
  activeDocIdUpdate(state, payload) {
    state.activeDocId = payload;
  },
};
const actions = {
  docRenderedPageNumbersUpdate(context, payload) {
    context.commit("docRenderedPageNumbersUpdate", payload);
  },
  activeAddibleUpdate(context, payload) {
    context.commit("activeAddibleUpdate", payload);
  },
  activeDocIdUpdate(context, payload) {
    context.commit("activeDocIdUpdate", payload);
  },
};
const getters = {
  docRenderedPageNumbersGet: (state) => state.docRenderedPageNumbers,
  activeAddibleGet: (state) => state.activeAddible,
  activeDocIdGet: (state) => state.activeDocId,
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  actions,
  mutations,
};
