import { makeApiUrl } from "@/services/helpers";
import { cloneDeep } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { setFetchDefaults } from "@/services/helpers.js";
import { availableAddiblesByType } from "@/constants/addible.js";
import Vue from "vue";
import { bus } from "@/main";

const defaultState = {
  list: [
    // example: {id: uuid, objectUrl: '...', pagesCount: 0, order: 0, name: 'myfilename.pdf', mimetype: 'application/pdf' pagesDimensionRatio: [1.32, 1.32, ..., 1.6, 1.32], db_uuid?, db_id? }
  ],
  addibles: [
    // example:
    // {
    //   needAction: false,
    //   docId: "257b3498-39ff-4eb8-b34b-f60033fb95dy",
    //   id: "657b3498-39ff-4eb8-b34b-f60033fb95dt",
    //   iconName: "signature",
    //   type: "signature",
    //   name: "Signature",
    //   page: 1, // document page starting from 1
    //   x: 0.2, // left position of top-left corner of addible relative to pdf page. Between 0-1
    //   y: 0.9, // top position of top-left corner of addible relative to pdf page. Between 0-1
    // },
  ],
  // note: this helps a watcher detect changes
};

const mutations = {
  docsUpdate(state, payload) {
    Vue.set(state, "list", payload);
  },
  docUpdate(state, payload) {
    const otherDocs = state.list.filter((item) => item.id !== payload.id);
    state.list = [...otherDocs, payload];
  },
  docAdd(state, payload) {
    state.list = [...state.list, payload];
  },
  addiblesUpdate(state, payload) {
    Vue.set(state, "addibles", payload);
    bus.$emit("addibles-updated");
  },
};

const actions = {
  docUpdate(context, payload) {
    context.commit("docUpdate", payload);
  },
  docAdd(context, payload) {
    const newDoc = {
      pagesDimensionRatio: [],
      pagesCount: 1,
      order: context.getters.docListGet.length,
      id: uuidv4(),
      ...payload,
    };
    context.commit("docAdd", newDoc);
  },
  docRemove(context, id) {
    context.state.list = context.state.list.filter((item) => item.id !== id);
  },
  addibleAdd(context, payload) {
    const newAddible = _makeAddible(payload);
    context.commit("addiblesUpdate", [...context.state.addibles, newAddible]);
    return newAddible;
  },
  addiblesAdd(context, payload = []) {
    const newAddibles = payload.map((addible) => _makeAddible(addible));
    context.commit("addiblesUpdate", [...context.state.addibles, ...newAddibles]);
  },
  addibleUpdate(context, payload) {
    const otherAddibles = context.state.addibles.filter((item) => item.id !== payload.id);
    return context.commit("addiblesUpdate", cloneDeep([...otherAddibles, payload]));
  },
  addibleRemove(context, payload) {
    const otherAddibles = context.state.addibles.filter((item) => item.id !== payload.id);
    return context.commit("addiblesUpdate", cloneDeep([...otherAddibles]));
  },
  docPagesCountUpdater(context, { docId, pagesCount }) {
    const doc = context.getters.docByIdGet(docId);
    const newDoc = { ...doc, pagesCount };
    context.dispatch("docUpdate", newDoc);
  },
  pagesDimensionRatioUpdate(context, { docId, pageNumber, pageDimensionRatio }) {
    const doc = context.getters.docByIdGet(docId);
    const pagesDimensionRatio = cloneDeep(doc.pagesDimensionRatio);
    pagesDimensionRatio[pageNumber - 1] = pageDimensionRatio;
    const newDoc = { ...doc, pagesDimensionRatio };
    context.dispatch("docUpdate", newDoc);
  },
  // convert image to pdf
  fetchPdfOfImageByUrl(context, { imageUrl }) {
    const url = makeApiUrl("/image-to-pdf/by-url");
    const options = {
      method: "POST",
      body: JSON.stringify({
        imageUrl,
      }),
    };
    return fetch(url, setFetchDefaults(options)).then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.blob();
    });
  },
  // convert image to pdf
  fetchPdfOfImageByFile(context, { imageFile }) {
    const url = makeApiUrl("/image-to-pdf/by-file");
    const formData = new FormData();
    formData.append("imageFile", imageFile);
    const options = {
      method: "POST",
      body: formData,
    };
    return fetch(url, options).then(async (res) => {
      if (!res.ok) {
        throw await res.json();
      }
      return res.blob();
    });
  },
  clearDocStore(context) {
    context.commit("docsUpdate", []);
    context.commit("addiblesUpdate", []);
  },
};
const getters = {
  docByIdGet: (state, getters) => (docId) => {
    return getters.docListGet.find((item) => item.id === docId);
  },
  docListGet(state) {
    return state.list.sort(byOrder);

    function byOrder(a, b) {
      return a.order - b.order;
    }
  },
  docAddiblesGet: (state) => (docId) => {
    if (!docId) {
      return [];
    }
    return state.addibles.filter((item) => item.docId === docId);
  },
  allAddiblesGet: (state) => {
    return state.addibles;
  },
  allAddiblesApiAdaptedGet: (state, getters) => {
    return getters.allAddiblesGet.map((addible) => {
      const { x: position_left, y: position_top, page: position_page, docId: doc_uuid, type } = addible;
      return {
        doc_uuid,
        position_page,
        position_left,
        position_top,
        type,
      };
    });
  },
};

function _makeAddible(payload) {
  return { id: uuidv4(), needAction: availableAddiblesByType[payload.type].hasAction, ...payload };
}

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  mutations,
  actions,
};
