import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash-es";
import jwt_decode from "jwt-decode";

const APP_NAME = "docsign";
const CONNECTION_COMMANDS = {
  GET_CASE_PERSONS: "GET_CASE_PERSONS",
  GET_APPLICANT_PERSON: "GET_APPLICANT_PERSON",
  GET_COMPANY: "GET_COMPANY",
  GET_DOCUMENTS_CATEGORIES: "GET_DOCUMENTS_CATEGORIES",
};

const defaultState = {
  isInIframe: _isInIframe(),
  companyEncoded: "",
  // company: {
  //   name: "Tesla",
  //   uuid: "81c53a8d-gd93-8285-89af-8188e35adb9t",
  //   logoUrl: "http://placehold.it/128x128",
  // },
  applicantPersonEncoded: "",
  // fixme: make empty
  applicantPerson: {
    // email: "m.ziaeemehr+applicant@gmail.com",
    // name: "todo:applicant name",
    // uuid: "6fcaa7bb-45ba-4d11-83a4-0c5b57a3d6ba",
  },
  casePersonsEncoded: "",
  // fixme: make empty
  casePersons: [
    // {
    //   uuid: "todo:uuid1",
    //   name: "John Doe",
    //   email: "m.ziaeemehr+1@gmail.com",
    // },
  ],
  categories: [],
};

const mutations = {
  casePersonsUpdate(state, payload) {
    // console.log("casePersonsUpdate", payload);
    state.casePersons = payload;
  },
  applicantPersonUpdate(state, payload) {
    // console.log("applicantPersonUpdate", payload);
    state.applicantPerson = payload;
  },
  applicantPersonEncodedUpdate(state, payload) {
    // console.log("applicantPersonEncodedUpdate", payload);
    state.applicantPersonEncoded = payload;
  },
  casePersonsEncodedUpdate(state, payload) {
    // console.log("casePersonsEncodedUpdate", payload);
    state.casePersonsEncoded = payload;
  },
  companyUpdate(state, payload) {
    // console.log("companyUpdate", payload);
    state.company = payload;
  },
  companyEncodedUpdate(state, payload) {
    // console.log("companyEncodedUpdate", payload);
    state.companyEncoded = payload;
  },
  categoriesUpdate(state, payload) {
    state.categories = payload;
  },
};
const actions = {
  casePersonsFetch(context, payload) {
    return _sendMessageThenListen({
      command: CONNECTION_COMMANDS.GET_CASE_PERSONS,
      messageId: uuidv4(),
    }).then((response) => {
      const casePersons = response.map((item) => jwt_decode(item.encoded));
      const casePersonsEncoded = response.map((item) => {
        const decoded = jwt_decode(item.encoded);
        return { [decoded.uuid]: item.encoded };
      });
      context.commit("casePersonsEncodedUpdate", casePersonsEncoded);
      return context.commit("casePersonsUpdate", casePersons);
    });
  },
  applicantPersonFetch(context, payload) {
    return _sendMessageThenListen({
      command: CONNECTION_COMMANDS.GET_APPLICANT_PERSON,
      messageId: uuidv4(),
    }).then((response) => {
      const decoded = jwt_decode(response.encoded);
      context.commit("applicantPersonEncodedUpdate", response.encoded);
      context.commit("applicantPersonUpdate", decoded);
    });
  },
  companyFetch(context, payload) {
    return _sendMessageThenListen({
      command: CONNECTION_COMMANDS.GET_COMPANY,
      messageId: uuidv4(),
    }).then((response) => {
      context.commit("companyEncodedUpdate", response.encoded);
      context.commit("companyUpdate", response.decoded);
    });
  },
  categoriesFetch(context, payload) {
    return _sendMessageThenListen({
      command: CONNECTION_COMMANDS.GET_DOCUMENTS_CATEGORIES,
      messageId: uuidv4(),
    }).then((response) => {
      return context.commit("categoriesUpdate", response);
    });
  },
};
const getters = {
  categoriesGet: (state) => {
    return state.categories.map((category, iIndex) => {
      const id = iIndex + 1;
      let updatedCategory = Object.assign({}, category, {
        id,
        subcategories: category.subcategories.map((subcategory, jIndex) => {
          const subId = jIndex + 1;
          return Object.assign({}, subcategory, { id: subId });
        }),
      });
      return updatedCategory;
    });
  },
  casePersonsGet: (state) => state.casePersons,
  casePersonsEncodedGet: (state) => state.casePersonsEncoded,
  applicantPersonGet: (state) => state.applicantPerson,
  applicantPersonEncodedGet: (state) => state.applicantPersonEncoded,
  companyGet: (state) => state.company,
  companyEncodedGet: (state) => state.companyEncoded,
  isInIframeGet: (state) => state.isInIframe,
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  actions,
  mutations,
};

function _sendMessageThenListen({ command, payload, messageId }) {
  return new Promise((resolve) => {
    window.addEventListener("message", _onEvent);
    _sendPostMessage({ appName: APP_NAME, command, payload, messageId });

    function _onEvent(event) {
      if (event?.data?.originalData?.messageId === messageId) {
        _removeListener();
        resolve(event?.data?.payload);
      }
    }

    function _removeListener() {
      window.removeEventListener("message", _onEvent);
    }
  });
}

function _sendPostMessage(message) {
  const upperWindow = window.parent;
  if (parent) {
    upperWindow.postMessage(message, "*");
  } else if (_isInIframe()) {
    console.error("App did not detect its parent window.");
  }
}

function _isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
