import Vue from "vue";
import VueTailwind from "vue-tailwind";

/*
import {
  TInput,
  TTextarea,
  TSelect,
  TRadio,
  TCheckbox,
  TButton,
  TInputGroup,
  TCard,
  TAlert,
  TModal,
  TDropdown,
  TRichSelect,
  TPagination,
  TTag,
  TRadioGroup,
  TCheckboxGroup,
  TTable,
  TDatepicker,
  TToggle,
  TDialog,
} from "vue-tailwind/dist/components";

const settings = {
  // Use the following syntax
  // {component-name}: {
  //   component: {importedComponentObject},
  //   props: {
  //     {propToOverride}: {newDefaultValue}
  //     {propToOverride2}: {newDefaultValue2}
  //   }
  // }
  // "t-input": {
  //   component: TInput,
  //   props: {
  //     classes: "border-2 block w-full rounded text-gray-800",
  //     // ...More settings
  //   },
  // },
  "t-textarea": {
    component: TTextarea,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      // ...More settings
    },
  },

  "normal-button": {
    component: TButton,
    props: {
      // See "override settings" section for more info about this
      type: "button",
    },
  },
  "submit-button": {
    component: TButton,
    props: {
      // See "override settings" section for more info about this
      type: "submit",
    },
  },
  // ...Rest of the components
};

Vue.use(VueTailwind, settings);
*/

import {
  TInput,
  TTextarea,
  TSelect,
  TRadio,
  TCheckbox,
  TButton,
  TInputGroup,
  TCard,
  TAlert,
  TModal,
  TDropdown,
  TRichSelect,
  TPagination,
  TTag,
  TRadioGroup,
  TCheckboxGroup,
  TTable,
  TDatepicker,
  TToggle,
  TDialog,
} from "vue-tailwind/dist/components";

const components = {
  "t-datepicker": {
    component: TDatepicker,
  },
  TSelect,
  TRadio,
  "t-toggle": {
    component: TToggle,
    props: {
      fixedClasses: {
        wrapper: "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
        wrapperChecked:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-blue focus:outline-none focus:ring-opacity-50",
        wrapperDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
        wrapperCheckedDisabled:
          "relative inline-flex flex-shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-blue focus:outline-none focus:ring-opacity-50",
        button: "inline-block absolute transform translate-x-0.5 transition ease-in-out duration-200 translate-y-1",
        buttonChecked:
          "inline-block absolute transform translate-x-6 transition ease-in-out duration-200 translate-y-1",
        checkedPlaceholder: "inline-block",
        uncheckedPlaceholder: "inline-block",
      },
      classes: {
        wrapper:
          "bg-red rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-green rounded-full",
        wrapperDisabled:
          "bg-red rounded-full border-2 border-transparent focus:ring-2 focus:ring-blue focus:outline-none focus:ring-opacity-50",
        wrapperCheckedDisabled: "bg-blue",
        button: "h-3 w-3 rounded-full bg-white shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked: "h-3 w-3 rounded-full bg-white shadow flex items-center justify-center text-blue text-xs",
        checkedPlaceholder: "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder: "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
      },
    },
  },
  TTextarea,
  "t-textarea": {
    component: TTextarea,
    props: {
      classes:
        "bg-gray-lightest border border-gray-dark border-opacity-50 rounded block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed",
    },
  },
  "t-radio-group": {
    component: TRadioGroup,
    props: {
      classes: {
        groupWrapper: "flex flex-row",
        label: "ml-2 mr-8 text-gray-dark uppercase text-sm",
        input:
          "text-green transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-green focus:ring-2 focus:ring-green focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      },
    },
  },
  "t-input": {
    component: TInput,
    props: {
      classes:
        "bg-gray-lightest block w-full px-3 py-3 text-black placeholder-gray-400 transition duration-100 ease-in-out border border-gray-dark border-opacity-50 rounded shadow-sm focus:border-blue focus:ring-2 focus:ring-blue focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
    },
  },
  "t-modal": {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: "z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50",
        wrapper: "modal-wrapper relative mx-auto z-50 max-w-3xl px-3 py-0 my-12",
        modal: "overflow-visible relative  rounded-lg",
        body: "p-10",
        header: "h2 pt-10 pb-0 px-6 lg:px-10 border-none rounded-t",
        footer: " p-3 rounded-b",
        close:
          "flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50",
      },
      classes: {
        overlay: "bg-black",
        wrapper: "",
        modal: "bg-white shadow",
        body: "p-3",
        header: "border-gray-100",
        footer: "bg-gray-100",
        close: "bg-gray-100 text-gray-600 hover:bg-gray-200",
        closeIcon: "fill-current h-4 w-4",
        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",
        enterClass: "",
        enterActiveClass: "",
        enterToClass: "",
        leaveClass: "",
        leaveActiveClass: "",
        leaveToClass: "",
      },
    },
  },
  "t-checkbox": {
    component: TCheckbox,
    props: {
      wrapped: true,
      classes: {
        wrapper: "flex items-center",
        inputWrapper: "flex",
        label: "select-none ml-2 font-medium",
        input: "align-center",
      },
      variants: {
        danger: {
          wrapper: "flex items-center",
          inputWrapper: "flex",
          label: "select-none ml-2 font-medium text-red",
          input: "align-center border-red",
        },
      },
    },
  },
  "t-checkbox-group": {
    component: TCheckboxGroup,
    props: {
      wrapped: true,
      classes: {
        wrapper: "flex items-center mr-4",
        inputWrapper: "flex",
        label: "select-none ml-1",
        input: "align-center",
      },
    },
  },
  "t-card": {
    component: TCard,
    props: {
      classes: {
        wrapper: "rounded-md bg-white shadow-xl",
        body: "p-6",
      },
      variants: {
        deep: {
          wrapper: "rounded bg-gray-lightest",
        },
        flat: {
          wrapper: "bg-white",
        },
      },
    },
  },
  "t-table": {
    component: TTable,
    props: {
      classes: {
        table: "min-w-full divide-y divide-gray-dark rounded overflow-hidden",
        thead: "",
        theadTr: "",
        theadTh: "px-3 py-2 font-medium text-left bg-orange-lightest border-b border-orange-lightest",
        tbody: "bg-gray-lightest",
        tr: "",
        td: "px-3 py-2 whitespace-no-wrap text-sm",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",
      },
      variants: {},
    },
  },
  "t-button": {
    component: TButton,
    props: {
      // The fixed classes will never change and will be merged with the `classes` value or the active variant
      fixedClasses:
        "focus:shadow-outline focus:outline-none inline-flex items-center transition ease-in-out duration-150 disabled:opacity-50",
      // Classes used when any variant is active
      classes:
        "text-black bg-blue hover:bg-blue-light focus:border-blue active:bg-blue text-sm font-medium px-3 py-2 rounded-md border border-transparent",
      variants: {
        "info-light":
          "text-black bg-blue-light hover:bg-blue focus:border-blue active:bg-blue text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        info:
          "text-white bg-blue hover:bg-blue-light focus:border-blue active:bg-blue-light text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        success:
          "text-white bg-green hover:bg-green focus:border-green active:bg-green text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        "success-outline":
          "text-green bg-white hover:bg-white border border-green text-sm font-medium px-3 py-2 rounded-md",
        pink:
          "text-black bg-red-light hover:bg-red-lightest focus:border-red-light active:bg-red-light text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        danger:
          "text-red bg-red-lightest hover:bg-red-lightest focus:border-red-light active:bg-red-light text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        warning:
          "text-black bg-orange hover:bg-orange-light focus:border-orange active:bg-orange text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        neutral:
          "text-black bg-gray hover:bg-gray-light focus:border-gray active:bg-gray text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        teal:
          "text-white bg-teal hover:bg-teal-light focus:border-teal active:bg-teal text-sm font-medium px-3 py-2 rounded-md border border-transparent",
        white: "text-blue bg-white text-md font-medium px-3 py-2 rounded-md border border-transparent",
        infoIcon: "text-blue border border-blue text-md rounded-md p-2",
        successIcon: "text-green bg-green-light border border-green text-md rounded-md p-2",
        dangerIcon: "text-red bg-red-lightest border border-red text-md rounded-md p-2",
        warningIcon: "text-orange bg-orange-light border border-orange text-md rounded-md p-2",
      },
      // ...More settings
    },
  },
};

Vue.use(VueTailwind, components);
