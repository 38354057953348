import { makeApiUrl, setFetchDefaults } from "@/services/helpers";
import { cloneDeep } from "lodash-es";

const defaultState = {
  casePerson: {
    name: "",
    email: "",
    uuid: "",
  },

  stampFullName: "",
  stampImageDataUrl: "",
  stampIncludeDateWithName: false,

  // one of [type, draw, image, mobile]
  signatureType: "",
  signatureText: "",
  signatureTextDataUrl: "",
  signatureDrawDataUrl: "",
  signatureImageDataUrl: "",
  signaturePhoneNumber: "",
  signatureVerifyCode: "",
  signaturePhoneNumberAcceptableToken: "",

  initialImageDataUrl: "",
  initialFullName: "",

  signAbilityToken: "",

  signaturePreviewUrl: "",
};

const mutations = {
  casePersonUpdate(state, payload) {
    state.casePerson = payload;
  },
  stampImageDataUrlUpdate(state, payload) {
    state.stampImageDataUrl = payload;
  },
  stampIncludeDateWithNameUpdate(state, payload) {
    state.stampIncludeDateWithName = payload;
  },
  stampFullNameUpdate(state, payload) {
    state.stampFullName = payload;
  },
  signatureTypeUpdate(state, payload) {
    state.signatureType = payload;
  },
  signatureTextUpdate(state, payload) {
    state.signatureText = payload;
  },
  signatureTextDataUrlUpdate(state, payload) {
    state.signatureTextDataUrl = payload;
  },
  signatureDrawDataUrlUpdate(state, payload) {
    state.signatureDrawDataUrl = payload;
  },
  signatureImageDataUrlUpdate(state, payload) {
    state.signatureImageDataUrl = payload;
  },
  signaturePhoneNumberUpdate(state, payload) {
    state.signaturePhoneNumber = payload;
  },
  signatureVerifyCodeUpdate(state, payload) {
    state.signatureVerifyCode = payload;
  },
  signaturePhoneNumberAcceptableTokenUpdate(state, payload) {
    state.signaturePhoneNumberAcceptableToken = payload;
  },
  initialImageDataUrlUpdate(state, payload) {
    state.initialImageDataUrl = payload;
  },
  initialFullNameUpdate(state, payload) {
    state.initialFullName = payload;
  },
  signAbilityTokenUpdate(state, payload) {
    state.signAbilityToken = payload;
  },
  signaturePreviewUrlUpdate(state, payload) {
    state.signaturePreviewUrl = payload;
  },
};
const actions = {
  casePersonUpdate(context, payload) {
    context.commit("casePersonUpdate", payload);
  },
  stampImageDataUrlUpdate(context, payload) {
    context.commit("stampImageDataUrlUpdate", payload);
  },
  stampIncludeDateWithNameUpdate(context, payload) {
    context.commit("stampIncludeDateWithNameUpdate", payload);
  },
  stampFullNameUpdate(context, payload) {
    context.commit("stampFullNameUpdate", payload);
  },
  signatureTypeUpdate(context, payload) {
    context.commit("signatureTypeUpdate", payload);
  },
  signatureTextUpdate(context, payload) {
    context.commit("signatureTextUpdate", payload);
  },
  signatureTextDataUrlUpdate(context, payload) {
    context.commit("signatureTextDataUrlUpdate", payload);
  },
  signatureDrawDataUrlUpdate(context, payload) {
    context.commit("signatureDrawDataUrlUpdate", payload);
  },
  signatureImageDataUrlUpdate(context, payload) {
    context.commit("signatureImageDataUrlUpdate", payload);
  },
  signaturePhoneNumberUpdate(context, payload) {
    context.commit("signaturePhoneNumberUpdate", payload);
  },
  signatureVerifyCodeUpdate(context, payload) {
    context.commit("signatureVerifyCodeUpdate", payload);
  },
  signaturePhoneNumberAcceptableTokenUpdate(context, payload) {
    context.commit("signaturePhoneNumberAcceptableTokenUpdate", payload);
  },
  initialImageDataUrlUpdate(context, payload) {
    context.commit("initialImageDataUrlUpdate", payload);
  },
  initialFullNameUpdate(context, payload) {
    context.commit("initialFullNameUpdate", payload);
  },

  async fetchSignaturePreview(context) {
    if (!context.getters.signatureTypeGet) {
      return;
    }

    const signatureType = context.getters.signatureTypeGet;
    const signatureProp = context.getters.signaturePropGet;

    const imageBlob = await fetchPreview().catch((error) => {
      console.log(error);
      return;
    });
    if (!imageBlob) {
      return;
    }
    const signaturePreviewUrl = URL.createObjectURL(imageBlob);
    context.commit("signaturePreviewUrlUpdate", signaturePreviewUrl);

    async function fetchPreview() {
      const url = makeApiUrl("/signature-preview");
      const options = {
        method: "POST",
        body: JSON.stringify({
          signature_type: signatureType,
          ...signatureProp,
        }),
      };
      return fetch(url, setFetchDefaults(options)).then(async (res) => {
        if (!res?.ok) {
          throw await res.json();
        }
        return await res.blob();
      });
    }
  },

  signaturePhoneNumberVerifyRequest(context, { phoneNumber }) {
    const url = makeApiUrl("/verify-phone-request");
    const options = {
      method: "POST",
      body: JSON.stringify({
        phoneNumber,
      }),
    };
    return fetch(url, setFetchDefaults(options)).then(async (res) => {
      if (!res?.ok) {
        throw await res.json();
      }
      return await res.json();
    });
  },
  signaturePhoneNumberVerifyAnswer(context, { phoneNumber, code }) {
    const url = makeApiUrl("/verify-phone-answer");
    const options = {
      method: "POST",
      body: JSON.stringify({
        phoneNumber,
        code,
      }),
    };
    return fetch(url, setFetchDefaults(options)).then(async (res) => {
      if (!res?.ok) {
        throw await res.json();
      }
      return await res.json();
    });
  },

  signDocuments(context, { signRequestId }) {
    const {
      stampImageDataUrlGet,
      stampIncludeDateWithNameGet,
      signatureTypeGet,
      signatureTextGet,
      signatureDrawDataUrlGet,
      signatureTextDataUrlGet,
      signatureImageDataUrlGet,
      initialImageDataUrlGet,
      initialFullNameGet,
      signaturePhoneNumberAcceptableTokenGet,
      signAbilityTokenGet,
    } = context.getters;
    const data = {
      stamp_image_data_url: stampImageDataUrlGet,
      stamp_include_date_with_name: stampIncludeDateWithNameGet,
      signature_type: signatureTypeGet,
      signature_text: signatureTextGet,
      signature_text_data_url: signatureTextDataUrlGet,
      signature_draw_data_url: signatureDrawDataUrlGet,
      signature_image_data_url: signatureImageDataUrlGet,
      initial_image_data_url: initialImageDataUrlGet,
      initial_full_name: initialFullNameGet,
      signature_phone_number_proved_token: signaturePhoneNumberAcceptableTokenGet,
    };
    const url = makeApiUrl(`/sign-answer/${signRequestId}?accessToken=${signAbilityTokenGet}`);
    return fetch(url, setFetchDefaults({ method: "POST", body: JSON.stringify(data) })).then(async (res) => {
      if (!res?.ok) {
        throw await res.json();
      }
      return await res.json();
    });
  },
  signAbilityTokenUpdate(context, payload) {
    context.commit("signAbilityTokenUpdate", payload);
  },
};
const getters = {
  signAbilityTokenGet: (state) => state.signAbilityToken,
  casePersonGet: (state) => state.casePerson,
  stampImageDataUrlGet: (state) => state.stampImageDataUrl,
  stampIncludeDateWithNameGet: (state) => state.stampIncludeDateWithName,
  stampFullNameGet: (state) => state.stampFullName,
  signatureTypeGet: (state) => state.signatureType,
  signatureTextGet: (state) => state.signatureText,
  signatureTextDataUrlGet: (state) => state.signatureTextDataUrl,
  signatureDrawDataUrlGet: (state) => state.signatureDrawDataUrl,
  signatureImageDataUrlGet: (state) => state.signatureImageDataUrl,
  signaturePhoneNumberGet: (state) => state.signaturePhoneNumber,
  signatureVerifyCodeGet: (state) => state.signatureVerifyCode,
  initialImageDataUrlGet: (state) => state.initialImageDataUrl,
  initialFullNameGet: (state) => state.initialFullName,
  signaturePhoneNumberAcceptableTokenGet: (state) => state.signaturePhoneNumberAcceptableToken,
  signaturePropGet: (state, getters) => {
    const data1 = getters.signatureTextDataUrlGet;
    const data2 = getters.signatureDrawDataUrlGet;
    const data3 = getters.signatureImageDataUrlGet;
    const data4 = getters.signaturePhoneNumberGet;
    const providedToken = getters.signaturePhoneNumberAcceptableTokenGet;
    switch (getters.signatureTypeGet) {
      case "type":
        return { signature_text_data_url: data1, isValid: data1.length > 0 };
      case "draw":
        return { signature_draw_data_url: data2, isValid: data2.length > 0 };
      case "image":
        return { signature_image_data_url: data3, isValid: data3.length > 0 };
      case "mobile":
        return {
          signature_phone_number: data4,
          isValid: providedToken.length > 0,
        };
    }
  },
  signaturePreviewUrlGet: (state) => state.signaturePreviewUrl,
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  actions,
  mutations,
};
