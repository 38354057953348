import Vue from "vue";
import Vuex from "vuex";
import doc from "./modules/doc.js";
import editor from "./modules/editor.js";
import appGlobal from "./modules/appGlobal.js";
import proveId from "./modules/proveId.js";
import signRequest from "./modules/signRequest.js";
import documentViewer from "./modules/documentViewer.js";

Vue.use(Vuex);

export default new Vuex.Store({
  // state: {},
  // mutations: {},
  // actions: {},
  modules: { doc, editor, appGlobal, signRequest, proveId, documentViewer },
});
