import { cloneDeep } from "lodash-es";
import { makeApiUrl, setFetchDefaults } from "@/services/helpers.js";
import store from "@/store";

const defaultState = {
  // fixme: make empty
  recipients: [
    // {
    //   uuid: "1cd6be37-2f13-44cb-9557-397300add08d",
    //   name: "Sample1",
    //   email: "m.ziaeemehr+1@gmail.com",
    // },
  ],
  emailSubject: "",
  // privateMessages example: {"42055492-dbba-44a1-bc39-a754ff9298fa": "a text"}
  privateMessages: {},
  emailMessage: "",
  remindEveryXDay: false,
  expiresAt: null,
  signRequestsHistory: [],
};

const mutations = {
  emailSubjectUpdate(state, payload) {
    state.emailSubject = payload;
  },
  emailMessageUpdate(state, payload) {
    state.emailMessage = payload;
  },
  remindEveryXDayUpdate(state, payload) {
    state.remindEveryXDay = payload;
  },
  privateMessagesUpdate(state, payload) {
    state.privateMessages = payload;
  },
  expiresAtUpdate(state, payload) {
    state.expiresAt = payload;
  },
  signRequestsHistoryUpdate(state, payload) {
    state.signRequestsHistory = payload;
  },
  recipientsUpdate(state, payload) {
    state.recipients = payload;
  },
};
const actions = {
  privateMessagesUpdate(context, payload) {
    context.commit("privateMessagesUpdate", payload);
  },
  emailSubjectUpdate(context, payload) {
    context.commit("emailSubjectUpdate", payload);
  },
  emailMessageUpdate(context, payload) {
    context.commit("emailMessageUpdate", payload);
  },
  remindEveryXDayUpdate(context, payload) {
    context.commit("remindEveryXDayUpdate", payload);
  },

  async signRequestProcessFetch(context, { signRequestId, accessToken }) {
    const url = makeApiUrl(`/sign-requests/process/${signRequestId}?accessToken=${accessToken}`);
    const result = await fetch(url).then((response) => response.json());
    return result;
  },

  async signRequestsHistoryFetch(context) {
    const applicant_person_encoded = store.getters["appGlobal/applicantPersonEncodedGet"];
    const url = makeApiUrl("/sign-requests/history");
    const options = {
      method: "POST",
      body: JSON.stringify({ applicant_person_encoded }),
    };
    const result = await fetch(url, setFetchDefaults(options)).then((response) => response.json());
    context.commit("signRequestsHistoryUpdate", result);
  },

  async submitSignRequest(context) {
    let docs = store.getters["doc/docListGet"];
    let docFiles = [];
    for (let i = 0; i < docs.length; i++) {
      const doc = docs[i];
      const file = await fetch(doc.objectUrl)
        .then((res) => res.blob())
        .then((blobFile) => new File([blobFile], doc.name, { type: doc.mimetype }));
      docFiles.push(file);
    }
    const formData = new FormData();
    docFiles.forEach((docFile, index) => {
      formData.append(docs[index].id, docFile);
    });

    const docsInfo = docs.map((doc) => {
      const { id, pagesCount, order, name, mimetype, formId } = doc;
      return { id, pagesCount, order, name, mimetype, formId };
    });
    formData.append("docsInfo", jsonToBlob(docsInfo));

    const { recipients, emailSubject, emailMessage, privateMessages, remindEveryXDay, expiresAt } = context.state;
    const emailInfo = {
      subject: emailSubject,
      message: emailMessage,
      private_messages: privateMessages,
      remind_every_xday: remindEveryXDay,
      expires_at: expiresAt,
      recipients,
      case_persons_encoded: store.getters["appGlobal/casePersonsEncodedGet"],
      applicant_person_encoded: store.getters["appGlobal/applicantPersonEncodedGet"],
      company_encoded: store.getters["appGlobal/companyEncodedGet"],
    };
    formData.append("emailInfo", jsonToBlob(emailInfo));

    const docsAddibles = store.getters["doc/allAddiblesApiAdaptedGet"];
    formData.append("docsAddibles", jsonToBlob(docsAddibles));

    const options = {
      method: "POST",
      body: formData,
      headers: {},
    };
    const url = makeApiUrl("/sign-requests/process");
    return fetch(url, options).then(async (res) => {
      if (!res.ok) throw await res.json();
      return res.json();
    });
  },

  expiresAtUpdate(context, payload) {
    context.commit("expiresAtUpdate", payload);
  },

  recipientsUpdate(context, payload) {
    context.commit("recipientsUpdate", payload);
  },
};
const getters = {
  expiresAtGet: (state) => state.expiresAt,
  recipientsGet: (state) => state.recipients,
  privateMessagesGet: (state) => state.privateMessages,
  signRequestsHistoryGet: (state) => state.signRequestsHistory,
};

export default {
  namespaced: true,
  state: cloneDeep(defaultState),
  getters,
  actions,
  mutations,
};

function jsonToBlob(json) {
  const jsonString = JSON.stringify(json);
  const jsonBlob = new Blob([jsonString], {
    type: "application/json",
  });
  return jsonBlob;
}
