import { DATE_FORMAT_EASYREAD } from "@/config";
import { format, parseISO } from "date-fns";

const { VUE_APP_API_BASE } = process.env;

export function makeApiUrl(path) {
  return `${VUE_APP_API_BASE}${path}`;
}

export function setFetchDefaults(options) {
  const update = { ...options };
  if (!update.headers) {
    update.headers = {
      "content-type": "application/json",
    };
  }
  return update;
}

export function formatDate(date, formatTo = DATE_FORMAT_EASYREAD) {
  if (!date) return "";
  return format(date, formatTo);
}

export function formatDateString(dateString, formatTo = DATE_FORMAT_EASYREAD) {
  if (!dateString) return "";
  const date = parseISO(dateString);
  return format(date, formatTo);
}

export function pageTitle(title) {
  const { VUE_APP_TITLE } = process.env;
  return `${title} | ${VUE_APP_TITLE}`;
}
