import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("appGlobal", ["isInIframeGet"]),
  },
  methods: {
    resetIframeHeight() {
      if (this.isInIframeGet) {
        const appElement = document.getElementById("app");
        window.parentIFrame.size(appElement.clientHeight);
      }
    },
  },
};
